import * as React from 'react'

type BlogCaptionProps = {
  children: React.ReactNode
}

const BlogCaption = ({ children }: BlogCaptionProps) => {
  return (
    <figcaption
      data-testid="blog-caption"
      className={`text-sm text-grey-600 caption mb-12 mt-2`}
    >
      {children}
    </figcaption>
  )
}

export default BlogCaption

/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { titleCase } from 'title-case'

import postTidier from 'utils/postTidier'

import Layout from 'components/Layout'
import BlogBoxes from 'components/blog/BlogBoxes'
import Caption from 'components/blog/BlogCaption/BlogCaption'
import EmbeddedLink from 'components/blog/EmbeddedLink'
import ExternalLink from 'components/ExternalLink'
import Seo from '../Seo'
import { BlogPostQuery } from 'types'

type BlogPostPageProps = {
  data: {
    mdx: {
      frontmatter: {
        title: string
        date: string
        categories: string[]
        banner: {
          childrenImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }[]
        }
        tagline: string
      }
      body: string
    }
    allMdx: {
      nodes: BlogPostQuery[]
    }
  }
}

// shortcodes for passing in components
const shortcodes = { Caption, EmbeddedLink }

const Blockquote = ({ children }: { children: JSX.Element }) => {
  return (
    <blockquote>
      <h4>{children}</h4>
    </blockquote>
  )
}

const Paragraph = ({ children }: { children: JSX.Element }) => {
  return <p>{children}</p>
}

const BlogPostPage = ({ data }: BlogPostPageProps) => {
  const post = data.mdx
  const posts = postTidier(
    data.allMdx.nodes.filter((node) => node.fields.collection === 'blog')
  )

  return (
    <Layout>
      {/* HERO */}
      <div className="pt-12.5 page-section">
        <div className="relative inner-container">
          <div className="flex flex-row flex-wrap justify-center">
            <div className="w-full text-center">
              <div className="flex flex-col justify-center h-full">
                <div className="flex-none">
                  <p className="subtitle">
                    // {titleCase(post.frontmatter.categories[0])}
                  </p>
                  <h1 className="mt-5">{post.frontmatter.title}</h1>
                  <div className="inline-block mt-5 tag">
                    {post.frontmatter.date}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 mt-5">
              {post.frontmatter.banner && (
                <GatsbyImage
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                  image={
                    post.frontmatter.banner.childrenImageSharp[0]
                      .gatsbyImageData
                  }
                  alt={`banner image: ${post.frontmatter.title}`}
                  className="w-full"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* BLOG POST */}
      <div className="px-5 blog-post mt-20">
        {post.frontmatter.tagline && <p>{post.frontmatter.tagline}</p>}
        <MDXProvider
          components={{
            blockquote: Blockquote,
            a: ExternalLink,
            p: Paragraph,
            ...shortcodes,
          }}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <hr className="final-hr" />
        <p className="mt-7.5">
          Published on <strong>{post.frontmatter.date}</strong>
        </p>
      </div>
      {/* RELATED ARTICLES */}
      <div className="mt-30 inner-container mb-12">
        <h2>Related Articles</h2>
        <div className="mt-18.75">
          <BlogBoxes
            width="w-1/3"
            blogPosts={posts.slice(0, 3)}
            margins={{ title: 'mt-2.5', type: 'mt-7.5' }}
            imageHeight="h-52"
            spacing={{ margin: '-m-6.25', padding: 'p-6.25' }}
            excerptVisible={false}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        categories
        tagline
        banner {
          childrenImageSharp {
            gatsbyImageData(transformOptions: { fit: COVER })
          }
        }
      }
      body
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          slug
          banner {
            childrenImageSharp {
              gatsbyImageData(transformOptions: { fit: COVER })
            }
          }
          categories
        }
        fields {
          collection
        }
      }
    }
  }
`

export default BlogPostPage

export const Head = ({ data }: BlogPostPageProps) => {
  const post = data.mdx
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.tagline}
      image={
        post.frontmatter.banner
          ? post.frontmatter.banner.childrenImageSharp[0].gatsbyImageData.images
              .fallback?.src
          : 'blog_hero_bg.jpg'
      }
      imageBasePath=""
    />
  )
}
